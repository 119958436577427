<template>
  <div>
      <div class="card">
          <div class="card-header">
                <div class="card-title m-0">
                    <h3>Özel Fiyatlar</h3>
                </div>
          </div>
          <div class="card-body">
            <div id="match-form" ref="match-form">
                <div class="row mb-10">
                    <div class="col-lg-3">
                        <label class="col-form-label">Kullanıcı grubu</label>                            
                    </div>
                    <div class="col-lg-6">
                        <select name="" v-on:change="changeUserGroup" class="form-select form-select-solid" id="" v-model="userGroupId">
                            <option value="">Seçiniz</option>
                            <option :value="item.userGroupId" v-for="item in userGroups" :key="item.userGroupId">{{item.userGroupName}}</option>
                        </select>
                    </div>
                </div>
                <div class="row mb-10">
                    <div class="col-lg-3">
                        <label class="col-form-label">Fiyat</label>                            
                    </div>
                    <div class="col-lg-6">
                        <input type="number" name="" step="0.01" class="form-control form-control-solid" v-model="Price" id="">
                    </div>
                </div>
                <div class="row mb-10">
                    <div class="col-lg-3">
                        <label class="col-form-label">İskonto</label>                            
                    </div>
                    <div class="col-lg-6">
                        <input type="number" name="" step="0.01" class="form-control form-control-solid" v-model="Discount" id="">
                    </div>
                </div>

                
        </div>   
       


           
          </div>

          
        <div class="card-footer d-flex justify-content-end py-6 px-9">
          <button type="submit" id="kt_account_profile_details_submit" ref="submitButton1" @click.prevent="saveMatch()" class="btn btn-primary">
            <span class="indicator-label"> Kaydet </span>
            <span class="indicator-progress">
              Lütfen bekleyin...
              <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          </button>
        </div>
        
    </div>
  </div>
</template>


<script>
import api from '@/core/services/ApiService'
import Swal from "sweetalert2/dist/sweetalert2.js";

export default {
    name:'PackageSpecialPrices',
    props:['profile'],
    data(){
        return {
            selectedType:null,
            userGroups:null,
            userGroupId:null,
            price:null,
            discount:null,
            mode:'new',
            Price:null,
            Discount:null
        }
    },

    methods:{

        getPrices(id){
            
            
            api.get('/User/Get/Consultant/'+this.profile.userId+'/'+id).then((res)=>{
                
                this.Discount = res.data.discount;
                this.Price = res.data.price;
            })
        },

        changeUserGroup(){
            if(this.userGroupId!=""){
                this.getPrices(this.userGroupId);
            }else{
                this.Discount = null;
                this.Price = null;   
            }
        },
        getMatches(){
            
             api.get('/User/ListUserGroup').then((res)=>{
                this.userGroups = res.data;
            }).catch((err)=>{
                console.log(err);
            })
        },
        cancelSpecialPrices(){
            this.$emit('cancelSpecialPrices');
        },
        
        saveMatch(){
            let url = '/User/Update/Consultant/UserGroupPrice';
            let formData = new FormData;
            formData.append('UserGroupId',this.userGroupId);    // Method Field'leri gelecek.
            formData.append('price',this.Price);                //
            formData.append('discount',this.Discount);          //
            formData.append('CosultantId',this.profile.userId);
            let data = {
                UserGroupId:this.userGroupId,
                Price:this.Price,
                Discount:this.Discount,
                CosultantId:this.profile.userId,
            }
            api.post(url,data).then((res)=>{
                alert("ok");
            }).catch(e=>{
                console.log(e);
            });
        },
        
    },

    mounted(){
        console.log(this.profile);
        this.getMatches();
    }
}
</script>

<style>
.swal2-container .swal2-html-container{max-height: 500px !important;}

</style>